<!--
 * @Description: 客服用户服务管理 信息管理 帮助中心管理 helpCenterManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:35:04
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="帮助项标题">
              <el-input v-model="searchForm.supportTitle"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="帮助项状态">
              <el-select v-model="searchForm.supportStatusCode"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in statusList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="内容描述">
              <el-input v-model="searchForm.contentDesc"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="getList({pageNum:1})">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>帮助中心管理</span>
        <el-button class="tableTitleButton"
                   type="success"
                   @click="addHandle">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="detailsClick(scope.row)">详情</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="editClick(scope.row)">修改</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="deleteClick(scope.row)">删除</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button v-if="scope.row.supportStatusCode===1"
                             type="text"
                             size="medium"
                             @click="publisClick(scope.row)">发布</el-button>
                  <el-button v-else
                             type="text"
                             size="medium"
                             @click="stopPublish(scope.row)">停止发布</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="popClose">
      <!-- 新增表单 -->
      <el-form label-width="150px"
               :model="popForm"
               ref="popForm"
               :rules="rules"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="帮助项标题"
                          prop="supportTitle">
              <el-input v-model="popForm.supportTitle"
                        :readonly="dialogType===2? true:false"
                        placeholder="帮助项标题"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="帮助项内容描述"
                          prop="contentDesc">
              <el-input v-model="popForm.contentDesc"
                        :readonly="dialogType===2? true:false"
                        placeholder="帮助项内容描述"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-form-item v-if="dialogType!==2"
                        label="帮助项内容"
                        prop="content">
            <richText ref="richText"
                      v-model="popForm.content"
                      :height="420"
                      :uploadImgMaxLength="6"
                      @uploadImg="uploadImg"></richText>
          </el-form-item>
          <div v-else>
            <el-form-item label="帮助项内容"
                          prop="content">
            </el-form-item>
            <el-row class="richArea"
                    v-html="popForm.content"></el-row>
          </div>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end"
              v-show="dialogType!==2">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="popSubmit()"
                     :disabled="disabled"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="dialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import richText from "@/components/richText";
import Compressor from 'compressorjs'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    richText
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 15, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableColumnList: [
        {
          prop: 'supportTitle',
          label: '帮助项标题',
        },
        {
          prop: 'contentDesc',
          label: '帮助项内容描述',
        },
        {
          prop: 'supportStatusDesc',
          label: '帮助项状态',
        },
        {
          prop: 'createTime',
          label: '帮助项创建时间',
        },
        {
          prop: 'creater',
          label: '创建人',
        },
      ],
      tableList: {
        list: [
          {
            supportTitle: '',
            contentDesc: '',
            content: '',
            supportStatusDesc: '',
            createTime: '',
            creater: ''
          },
        ],
      },
      searchForm: {
        supportTitle: '',
        supportStatusCode: null,
        contentDesc: '',
        pageNum: 1,
        pageSize: 15
      },
      pageNum: 1,
      pageSize: 15,
      statusList: [{
        value: 1,
        label: '未发布'
      }, {
        value: 2,
        label: '已发布'
      }],
      noticeDate: [],
      popForm: {
        supportTitle: '',
        contentDesc: '',
        content: '',
      },
      // 弹出框类型 0新增，1修改，2详情
      dialogType: null,
      dialogTitle: '',//弹出框标题
      dialogVisible: false,//弹出框显示
      rules: {
        supportTitle: [
          { required: true, message: '请输入公告编号', trigger: 'blur' },
        ],
        contentDesc: [
          { required: true, message: '请输入公告名称', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入帮助项内容', trigger: 'changeText' },
        ],
      },
      disabled: false //禁用开关
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getList()
  },
  filters: {
  },
  //方法集合
  methods: {
    // 查询，获取列表
    getList (map = { pageNum: 1, pageSize: 15 }) {
      this.searchForm.pageNum = map.pageNum
      this.searchForm.pageSize = map.pageSize
      this.$helpCenterManage.querySupportList(this.searchForm).then(res => {
        res.resultEntity.list.sort((a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime())
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },

    //新增
    addHandle () {
      this.dialogVisible = true
      this.dialogType = 0
      this.dialogTitle = '新增公告'
      this.popForm = {
        supportTitle: '',
        contentDesc: '',
        content: '',
      }
      // 设置富文本默认内容
      this.$nextTick(() => {
        this.$refs.richText.setValue('')
      })
    },

    //修改
    editClick (row) {
      this.dialogType = 1
      this.dialogVisible = true
      this.dialogTitle = '修改公告'
      this.popForm = this.$deepClone(row)
      // 设置富文本默认内容
      this.$nextTick(() => {
        this.$refs.richText.setValue(row.content)
      })
    },

    //提交按钮
    popSubmit () {
      this.disabled = true;
      this.$refs['popForm'].validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.requestAdd()
          } else if (this.dialogType === 1) {
            this.requestUpdate()
          }
          this.dialogVisible = false
        } else {
          return false;
        }
      });
      setTimeout(() => {
        this.disabled = false;
      }, 1000)
    },

    // 新增请求
    requestAdd () {
      this.$helpCenterManage.addSupport(this.popForm).then(res => {
        if (res.resultCode == 2000) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.getList()
        }
      })
    },

    // 修改请求
    requestUpdate () {
      this.$helpCenterManage.updateSupport(this.popForm).then(res => {
        if (res.resultCode == 2000) {
          this.$message({ message: '修改成功', type: 'success' });
          this.getList()
        }
      })
    },

    // 删除请求
    deleteClick (row) {
      this.$confirm('是否删除公告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = [{
          supportId: row.supportId
        }]
        this.$helpCenterManage.deleteSupport(info).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.getList()
          }
        })
      })
    },
    // 发布请求
    publisClick (row) {
      this.$confirm('是否发布公告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          supportId: row.supportId
        }
        this.$helpCenterManage.publish(info).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '发布成功', type: 'success' });
            this.getList()
          }
        })
      })
    },
    // 停止发布
    stopPublish (row) {
      this.$confirm('是否停止发布公告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          supportId: row.supportId
        }
        this.$helpCenterManage.stopPublish(info).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '停止发布成功', type: 'success' });
            this.getList()
          }
        })
      })
    },
    // 详情
    detailsClick (row) {
      this.dialogType = 2
      this.dialogVisible = true
      this.dialogTitle = '公告详情'
      this.popForm = row
    },
    // 富文本上传图片
    uploadImg (resultFiles, insertImgFn) {
      resultFiles.forEach(file => {
        // 压缩图片
        const self = this
        new Compressor(file, {
          quality: 0.2,
          success (result) {
            // 将 Blob 对象转换成 File 对象
            result = new window.File([result], result.name, { type: result.type })
            const formData = new FormData()
            formData.append('file', result)
            self.$queryDict.upFile(formData).then(res => {
              insertImgFn(self.$imgBaseUrl + res.resultEntity)
            }).catch(err => {
              console.log('图片上失败', err)
            })
          },
          error (err) {
            console.log('压缩失败', err)
          }
        })
      })
    },
    // 弹出层关闭
    popClose () {
      this.$refs.popForm.clearValidate()
    },
    //重置
    resetForm () {
      this.searchForm = {
        supportTitle: '',
        supportStatusCode: '',
        contentDesc: '',
        pageNum: 1,
        pageSize: 15
      }
      this.getList()
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.richArea {
  height: 500px;
  padding: 10px;
  background: #ffffff;
  overflow: auto;
}
/*修改滚动条样式*/
// 滚动条最底部样式
.richArea::-webkit-scrollbar {
  width: 5px;
  background: #ffffff;
  /**/
}
// 滚动条背景条样式
.richArea::-webkit-scrollbar-track {
  background: #ffffff;
}
// 滚动条上层可滑动的条样式
.richArea::-webkit-scrollbar-thumb {
  background: #004179;
  border-radius: 5px;
}
// 滚动条上层可滑动的条样式:hover样式
.richArea::-webkit-scrollbar-thumb:hover {
  background: #013b6d;
}

/deep/.el-textarea {
  width: 690px;
}
</style>